<template>
    <div class="row">
        <div class="col-12">
            <div class="p-3">
                <!-- modal for add new pull zone -->
                <b-overlay :show="add_pullzone_show_overlay" rounded="sm">
                    <b-modal
                        id="modal-create"
                        cancel-variant="secondary"
                        ok-title="Add"
                        cancel-title="Cancel"
                        centered
                        title="Create new pull zone"
                        @ok="createPullZone"
                    >
                        <b-form>
                            <b-form-group label="Origin Url" label-for="originUrl">
                                <b-form-input id="originUrl" placeholder="origin url ex. https://www.domain.com" v-model="originUrl" />
                            </b-form-group>
                            <b-form-group label="Pull zone tag(optional)" label-for="pullzoneTag">
                                <b-form-input id="pullzoneTag" placeholder="tag(optional)" v-model="pullzoneTag" />
                            </b-form-group>
                        </b-form>
                    </b-modal>
                </b-overlay>
            </div>
        </div>

        <!-- table -->
        <div class="col-12">
            <b-card header="Pull Zone List">
                <div class="pr-3 pb-3 pl-3">
                    <b-row class="mb-3 mt-3">
                        <b-col v-if="$store.state.auth.my_roles.includes('user')">
                            <b-button
                                class="mr-3"
                                variant="primary"
                                v-b-modal.modal-create
                                @click="
                                    originUrl = '';
                                    pullzoneTag = '';
                                "
                            >
                                Create pull zone
                            </b-button>
                            <b-button variant="secondary" @click="refreshTable"><feather-icon icon="RotateCcwIcon" size="15" /></b-button>
                        </b-col>
                        <b-col v-if="$store.state.auth.my_roles.includes('admin') || $store.state.auth.my_roles.includes('read_only')">
                            <VDropdown>
                                <b-button variant="secondary" class="mb-2">
                                    <feather-icon icon="SearchIcon" class="mr-2" size="15" />
                                    <span class="align-middle">Search-items</span>
                                </b-button>
                                <!-- This will be the content of the popover -->
                                <template #popper>
                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="pull zone id" label-for="pullZoneId">
                                                <b-form-input placeholder="id" v-model="queryCondition.id" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="pull zone origin" label-for="origin">
                                                <b-form-input placeholder="origin" v-model="queryCondition.origin" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="pull zone origin pattern" label-for="originPattern">
                                                <b-form-input placeholder="origin_pattern" v-model="queryCondition.origin_pattern" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="pull zone name" label-for="name">
                                                <b-form-input placeholder="name" v-model="queryCondition.name" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="user id" label-for="userId">
                                                <b-form-input placeholder="user id" v-model="queryCondition.userid" />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row>
                                        <b-col class="pl-0 mt-1 ml-3">
                                            <b-form-group label="status" label-for="status">
                                                <v-select
                                                    id="status"
                                                    label="text"
                                                    :reduce="(text) => text.value"
                                                    placeholder="status"
                                                    v-model="queryCondition.forbidden"
                                                    :options="StatusOption"
                                                />
                                            </b-form-group>
                                        </b-col>
                                    </b-row>

                                    <b-row class="mb-1 mt-3">
                                        <b-col>
                                            <b-button variant="secondary" v-close-popper="true" @click="search"> Search</b-button>
                                            <b-button class="ml-2" variant="secondary" @click="clearQueryCondition"> Clear</b-button>
                                        </b-col>
                                    </b-row>
                                </template>
                            </VDropdown>
                        </b-col>
                    </b-row>

                    <b-overlay :show="table_show_overlay" rounded="sm">
                        <vue-good-table
                            ref="remote_q_table"
                            @on-page-change="onPageChange"
                            @on-per-page-change="onPerPageChange"
                            :totalRows="totalRows"
                            :columns="columns"
                            :rows="row_data"
                            :sort-options="{ enabled: false }"
                            :pagination-options="{
                                enabled: true,
                                mode: 'pages',
                                perPage: 10,
                                perPageDropdown: [5, 10, 20, 50, 100, 250, 500, 1000],
                                setCurrentPage: 1,
                            }"
                        >
                            <template slot="table-row" slot-scope="props">
                                <!-- Column: Common -->
                                <span v-if="props.column.field === 'name'">
                                    <feather-icon
                                        icon="CopyIcon"
                                        class="cursor-pointer"
                                        @click="copyCommand(`https://pz-${props.row.name}.meson.network`)"
                                        v-b-tooltip.hover.left="'click to copy'"
                                    />

                                    <span class="ml-2">https://pz-{{ props.row.name }}.meson.network</span>
                                </span>

                                <span v-else-if="props.column.field === 'forbidden'">
                                    <b-badge v-if="!props.row['forbidden']" pill variant="success" class="mr-3">active</b-badge>
                                    <b-badge v-else pill class="mr-3">forbidden</b-badge>
                                </span>

                                <span v-else-if="props.column.field === 'userid'">
                                    {{ props.row[props.column.field] }}
                                </span>

                                <span v-else-if="props.column.field === 'origin'">
                                    <span v-if="props.row[props.column.field].length > 40"
                                        ><span v-b-tooltip.hover.up="props.row[props.column.field]">
                                            {{ props.row[props.column.field].substr(0, 40) + "..." }}
                                        </span>
                                    </span>
                                    <span v-else> {{ props.row[props.column.field] }} </span>
                                </span>

                                <span v-else-if="props.column.field === 'action1'">
                                    <b-button @click="gotoStatisticsPullZone(props.row.originalIndex)">
                                        <feather-icon icon="PieChartIcon" class="mr-2" size="11" />
                                        <span class="align-middle">statistics</span>
                                    </b-button>

                                    <b-button class="ml-1" variant="primary" @click="open_row_view_edit(props.row.originalIndex)"
                                        >Edit/Delete</b-button
                                    >

                                    <b-button
                                        v-if="props.row['forbidden'] === true"
                                        class="ml-1"
                                        variant="primary"
                                        @click="activePullZone(props.row.originalIndex)"
                                        >Activate</b-button
                                    >
                                </span>

                                <span v-else>
                                    {{ props.row[props.column.field] }}
                                </span>
                            </template>
                        </vue-good-table>
                    </b-overlay>

                    <!-- modal_row_view_update-->
                    <b-modal id="modal_row_view_update" @hide="handleHide" title="view/edit" cancel-variant="secondary" cancel-title="Close" centered>
                        <b-overlay :show="modal_row_view_overlay" rounded="sm">
                            <b-form>
                                <b-form-group label="id" label-for="id">
                                    <b-form-input id="id" placeholder="id" v-model="this.focus.id" disabled />
                                </b-form-group>

                                <b-form-group label="Name" label-for="name" v-if="$store.state.auth.my_roles.includes('admin')">
                                    <b-form-input id="name" placeholder="pull zone name" v-model="updatePullZoneInfo.name" />
                                </b-form-group>

                                <b-form-group label="Tag" label-for="tag">
                                    <b-form-input id="tag" placeholder="pull zone tag" v-model="updatePullZoneInfo.tag" />
                                </b-form-group>

                                <b-form-group v-if="$store.state.auth.my_roles.includes('admin')">
                                    <label>Forbidden:</label>
                                    <b-form-checkbox v-model="updatePullZoneInfo.forbidden" name="check-button" class="custom-control-danger" switch>
                                    </b-form-checkbox>
                                </b-form-group>
                            </b-form>
                        </b-overlay>

                        <template #modal-footer>
                            <b-row class="w-100">
                                <b-col class="text-left">
                                    <b-button variant="danger" @click="deletePullZone">Delete</b-button>
                                </b-col>
                                <b-col cols="8" class="text-right">
                                    <b-button variant="primary" class="mr-3" @click="updatePullZone">Update</b-button>
                                    <b-button variant="secondary" @click="$bvModal.hide('modal_row_view_update')">Cancel</b-button>
                                </b-col>
                            </b-row>
                        </template>
                    </b-modal>
                </div>
            </b-card>
        </div>
    </div>
</template>

<script>
import {
    BCard,
    BCardFooter,
    BRow,
    BCol,
    BInputGroup,
    BFormInput,
    BInputGroupPrepend,
    BButton,
    BOverlay,
    BBadge,
    BFormGroup,
    BFormInvalidFeedback,
    BAlert,
    BForm,
    BFormFile,
    BFormTags,
    BFormTextarea,
    BFormCheckbox,
    BFormCheckboxGroup,
    VBTooltip,
} from "bootstrap-vue";
import { VueGoodTable } from "vue-good-table";
import vSelect from "vue-select";

export default {
    components: {
        BCard,
        BCardFooter,
        BRow,
        BCol,
        BInputGroup,
        BFormInput,
        BInputGroupPrepend,
        BButton,
        BOverlay,
        BBadge,
        BFormGroup,
        BFormInvalidFeedback,
        BAlert,
        BForm,
        BFormFile,
        BFormTags,
        BFormTextarea,
        BFormCheckbox,
        BFormCheckboxGroup,
        VueGoodTable,
        VBTooltip,
        vSelect,
    },

    methods: {
        copyCommand(commandText) {
            this.$copyText(commandText).then(
                (e) => {
                    this.$bvToast.toast("copied", {
                        title: `Copied`,
                        variant: "success",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                },
                (e) => {
                    this.$bvToast.toast("copy failed", {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                }
            );
        },

        clearQueryCondition() {
            this.queryCondition = {
                id: null,
                origin: null,
                origin_pattern: null,
                name: null,
                forbidden: null,
                userid: null,
            };
        },

        onPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },
        onPerPageChange(params) {
            this.limit = params.currentPerPage;
            this.offset = (params.currentPage - 1) * params.currentPerPage;
            this.refreshTable();
        },

        open_row_view_edit(rowid) {
            this.focus = this.row_data[rowid];
            this.updatePullZoneInfo.name = this.focus.name;
            this.updatePullZoneInfo.tag = this.focus.tag;
            this.updatePullZoneInfo.forbidden = this.focus.forbidden;
            this.$bvModal.show("modal_row_view_update");
        },

        handleHide(bvModalEvt) {
            if (this.remote_updating) {
                bvModalEvt.preventDefault();
            }
        },

        gotoManagerPullZone(rowid) {
            this.$router.push({
                path: "/pullzone/" + this.row_data[rowid].id,
            });
        },

        gotoStatisticsPullZone(rowid) {
            this.$router.push({
                path: "/pullzone/statistics/traffic_usage/" + this.row_data[rowid].id + "/" + this.row_data[rowid].userid,
            });
        },

        async search() {
            this.$refs["remote_q_table"].reset();
            this.offset = 0;
            await this.refreshTable();
        },

        async refreshTable() {
            let id = this.queryCondition.id ? parseInt(this.queryCondition.id) : null;
            let origin = this.queryCondition.origin ? this.queryCondition.origin : null;
            let origin_pattern = this.queryCondition.origin_pattern ? this.queryCondition.origin_pattern : null;
            let name = this.queryCondition.name ? this.queryCondition.name : null;
            let userid = this.queryCondition.userid ? parseInt(this.queryCondition.userid) : null;
            let forbidden = this.queryCondition.forbidden;

            // queryPullZone(id, origin, origin_pattern, name,forbidden,userId, limit, offset, token)
            this.table_show_overlay = true;
            let resp = await this.$api.pullzone.queryPullZone(
                id,
                origin,
                origin_pattern,
                name,
                forbidden,
                userid,
                this.limit,
                this.offset,
                this.$store.state.auth.my_web_token
            );
            this.table_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.row_data = resp.result.pull_zone_list;
            this.totalRows = resp.result.count;
        },

        async createPullZone() {
            this.add_pullzone_show_overlay = true;
            let origin = this.originUrl;
            let tag = this.pullzoneTag;
            if (tag && tag.length > 20) {
                this.$bvToast.toast("tag length should less than 20", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }
            //check url

            let resp = await this.$api.pullzone.createPullZone(origin, tag, this.$store.state.auth.my_web_token);
            this.add_pullzone_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("new pull zone created", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });

            this.refreshTable();
        },

        async updatePullZone() {
            let id = this.focus.id;
            let name = this.updatePullZoneInfo.name;
            if (name === this.focus.name) {
                name = null;
            }
            let forbidden = this.updatePullZoneInfo.forbidden;
            let tag = this.updatePullZoneInfo.tag;
            if (tag && tag.length > 20) {
                this.$bvToast.toast("tag length should less than 20", {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.add_pullzone_show_overlay = true;

            let resp = await this.$api.pullzone.updatePullZone(id, name, tag, forbidden, this.$store.state.auth.my_web_token);
            this.add_pullzone_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("pull zone updated", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });

            this.refreshTable();
            this.$bvModal.hide("modal_row_view_update");
        },

        async activePullZone(rowid) {
            let value = await this.$bvModal.msgBoxConfirm(`Are you sure activate pull zone? `, {
                title: "Activate pull zone",
                okVariant: "primary",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (value) {
                let resp = await this.$api.pullzone.activate(this.row_data[rowid].id, this.$store.state.auth.my_web_token);

                if (resp.err !== null) {
                    this.$bvToast.toast(resp.err, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                if (resp.result.meta_status < 0) {
                    this.$bvToast.toast(resp.result.meta_message, {
                        title: `Error`,
                        variant: "danger",
                        solid: true,
                        toaster: "b-toaster-top-center",
                    });
                    return;
                }

                this.$bvToast.toast("pull zone active", {
                    title: `Success`,
                    variant: "success",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });

                await this.refreshTable();
            }
        },

        // async userDeletePullZone(rowid) {
        //     let value = await this.$bvModal.msgBoxConfirm(`Are you sure delete pull zone? `, {
        //         title: "Delete pull zone",
        //         okVariant: "primary",
        //         okTitle: "Yes",
        //         cancelTitle: "No",
        //         cancelVariant: "secondary",
        //         hideHeaderClose: false,
        //         centered: true,
        //     });
        //     if (value) {
        //         let resp = await this.$api.pullzone.deletePullZone(this.row_data[rowid].id, this.$store.state.auth.my_web_token);

        //         if (resp.err !== null) {
        //             this.$bvToast.toast(resp.err, {
        //                 title: `Error`,
        //                 variant: "danger",
        //                 solid: true,
        //                 toaster: "b-toaster-top-center",
        //             });
        //             return;
        //         }

        //         if (resp.result.meta_status < 0) {
        //             this.$bvToast.toast(resp.result.meta_message, {
        //                 title: `Error`,
        //                 variant: "danger",
        //                 solid: true,
        //                 toaster: "b-toaster-top-center",
        //             });
        //             return;
        //         }

        //         this.$bvToast.toast("pull zone deleted", {
        //             title: `Success`,
        //             variant: "success",
        //             solid: true,
        //             toaster: "b-toaster-top-center",
        //         });

        //         this.refreshTable();
        //     }
        // },

        async deletePullZone() {
            let value = await this.$bvModal.msgBoxConfirm(`Are you sure delete pull zone? `, {
                title: "Delete pull zone",
                okVariant: "primary",
                okTitle: "Yes",
                cancelTitle: "No",
                cancelVariant: "secondary",
                hideHeaderClose: false,
                centered: true,
            });
            if (!value) {
                return;
            }

            this.add_pullzone_show_overlay = true;
            let resp = await this.$api.pullzone.deletePullZone(this.focus.id, this.$store.state.auth.my_web_token);
            this.add_pullzone_show_overlay = false;

            if (resp.err !== null) {
                this.$bvToast.toast(resp.err, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            if (resp.result.meta_status < 0) {
                this.$bvToast.toast(resp.result.meta_message, {
                    title: `Error`,
                    variant: "danger",
                    solid: true,
                    toaster: "b-toaster-top-center",
                });
                return;
            }

            this.$bvToast.toast("pull zone deleted", {
                title: `Success`,
                variant: "success",
                solid: true,
                toaster: "b-toaster-top-center",
            });

            this.refreshTable();
            this.$bvModal.hide("modal_row_view_update");
        },
    },

    mounted() {
        this.refreshTable();
    },
    data() {
        return {
            //for table
            columns: [
                {
                    label: "ID",
                    field: "id",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Pull zone url",
                    field: "name",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Tag",
                    field: "tag",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Origin",
                    field: "origin",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "UserId",
                    field: "userid",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Status",
                    field: "forbidden",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
                {
                    label: "Action",
                    field: "action1",
                    tdClass: "text-center",
                    thClass: "text-center",
                },
            ],
            row_data: [],
            limit: 10,
            offset: 0,
            totalRows: 0,

            //for update and delete
            focus: {},

            table_show_overlay: true,
            modal_row_view_overlay: false,
            add_pullzone_show_overlay: false,

            queryCondition: {
                id: null,
                origin: null,
                origin_pattern: null,
                name: null,
                forbidden: null,
                userid: null,
            },

            updatePullZoneInfo: {
                name: "",
                pullzoneTag: null,
                forbidden: null,
            },

            originUrl: "",
            pullzoneTag: "",

            StatusOption: [
                { value: null, text: "All" },
                { value: false, text: "Active" },
                { value: true, text: "Forbidden" },
            ],
        };
    },
};
</script>
